// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import AdminLayout from "./components/_Layout/AdminLayout";
import LoginPage from "./pages/LoginPage";
import PublicPage from "./pages/PublicPage";
import Dashboard from "./pages/admin/Dashboard";
import Timecards from "./pages/admin/timecards/Timecard";
import TimecardDashboard from "./pages/admin/timecards/TimecardDashboard";
import TimecardEntries from "./pages/admin/timecard-entries/TimecardEntry";
import Projects from "./pages/admin/projects/Project";
import Settings from "./pages/admin/Settings";
import CustomerPage from "./pages/admin/customers/Customer";
import ProtectedRoute from "./components/_Auth/ProtectedRoute";
import RoleProtectedRoute from "./components/_Auth/RoleProtectedRoute";
import AlanPage from "./pages/admin/alan/Alan";
import ProjectPage from "./pages/admin/projects/Project";
import InventoryPage from "./pages/admin/inventory/Inventory";
import HygienePage from "./pages/admin/hygiene/Hygiene";

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<PublicPage />} />
                    <Route path="/login" element={<LoginPage />} />

                    {/* Protected Routes */}
                    <Route
                        path="/admin/*"
                        element={
                            <ProtectedRoute>
                                <AdminLayout />
                            </ProtectedRoute>
                        }
                    >
                        <Route path="dashboard" element={<Dashboard />} />

                        <Route path="alan" element={<AlanPage/>}/>
                        <Route path="projects" element={<ProjectPage/>}/>
                        <Route path="inventory" element={<InventoryPage/>}/>
                        <Route path="hygiene" element={<HygienePage/>}/>
                        <Route path="customers" element={<CustomerPage />} />
                        <Route path="timecards" element={<Timecards />} />
                        <Route path="timecard-dashboard" element={<TimecardDashboard />} />
                        <Route path="timecard-entries" element={<TimecardEntries />} />
                        <Route
                            path="settings"
                            element={
                                <RoleProtectedRoute requiredRole="Admin">
                                    <Settings />
                                </RoleProtectedRoute>
                            }
                        />
                    </Route>
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default App;