
import React from 'react';
import ProjectCrud from './ProjectCrud';

const ProjectPage = () => {
  return (
    <div>
      <h1>Work Orders</h1>
      <ProjectCrud />
    </div>
  );
};

export default ProjectPage;
