// src/pages/admin/customers/CustomerCrud.js
import React, { useEffect, useState, useCallback } from 'react';
import DataGrid from '../../../components/_CRUD/DataGrid';
import RightSidePanel from '../../../components/_CRUD/RightSidePanel';
import { customerConfig } from '../../../config/customer.config';
import { apiClient } from '../../../apiClient';
import { Box, Button, Heading, Flex, useDisclosure, useToast, useColorModeValue } from "@chakra-ui/react";
import { FiPlusCircle } from 'react-icons/fi';

const CustomerCrud = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(customerConfig.apiEndpoints.list);
      setData(response.data);
    } catch (error) {
      toast({
        title: 'Error fetching data.',
        description: error.response?.data?.error || 'Unable to retrieve data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreate = () => {
    setSelectedItem(null);
    onOpen();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedItem) {
        const endpoint = customerConfig.apiEndpoints.update.replace(':id', selectedItem._id);
        await apiClient.put(endpoint, formData);
        toast({
          title: 'Customer updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await apiClient.post(customerConfig.apiEndpoints.create, formData);
        toast({
          title: 'Customer created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      fetchData();
      onClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading size="lg">Customers</Heading>
        <Button leftIcon={<FiPlusCircle />} colorScheme="blue" onClick={handleCreate}>
          Add Customer
        </Button>
      </Flex>

      <Box
        bg={bg}
        borderRadius="lg"
        p={6}
        shadow="sm"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <DataGrid
          data={data}
          loading={loading}
          config={customerConfig}
          onEdit={handleEdit}
          fetchData={fetchData}
        />
      </Box>

      <RightSidePanel
        isOpen={isOpen}
        onClose={onClose}
        config={customerConfig}
        item={selectedItem}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default CustomerCrud;
