// src/components/_Layout/Footer.js
import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

const Footer = () => {
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      bg={bg}
      borderTopWidth="1px"
      borderColor={borderColor}
      p={4}
      textAlign="center"
    >
      <Text fontSize="sm">
        &copy; {new Date().getFullYear()} Kaeb Sales. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
