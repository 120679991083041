// File: /src/config/project.config.js
export const projectConfig = {
  entity: 'Work Order',
  apiEndpoints: {
    list: '/projects',
    create: '/projects',
    update: '/projects/:id',
    delete: '/projects/:id',
  },
  fields: [
    {
      name: 'woId',
      label: 'WO ID',
      type: 'text',
      required: true,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'woNumber',
      label: 'WO Number',
      type: 'text',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      type: 'text',
      required: false,
      defaultValue: null,
      showOnList: false,
      showOnForm: true
    },
    {
      name: 'descriptionOfWorkPerformed',
      label: 'Description Of Work',
      type: 'text',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'category',
      label: 'Category',
      type: 'number',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'customer',
      label: 'Customer',
      type: 'select',
      required: false,
      defaultValue: null,
      showOnList: false,
      showOnForm: true,
      optionsEndpoint: '/customers',
      optionsDataPath: 'customers',
      optionLabel: 'name',
      optionValue: '_id'
    },
    {
      name: 'site',
      label: 'Site',
      type: 'select',
      required: false,
      defaultValue: null,
      showOnList: false,
      showOnForm: true,
      optionsEndpoint: '/locations',
      optionsDataPath: 'locations',
      optionLabel: 'name',
      optionValue: '_id'
    },
    {
      name: 'location',
      label: 'Location',
      type: 'select',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true,
      optionsEndpoint: '/locations',
      optionsDataPath: 'locations',
      optionLabel: 'name',
      optionValue: '_id'
    },
    {
      name: 'teams',
      label: 'Teams',
      type: 'text',
      required: false,
      defaultValue: null,
      showOnList: false,
      showOnForm: true
    },
    {
      name: 'dateStarted',
      label: 'Date Started',
      type: 'date',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'dateCompleted',
      label: 'Date Completed',
      type: 'date',
      required: false,
      defaultValue: null,
      showOnList: true,
      showOnForm: true
    },
    {
      name: 'dateCreated',
      label: 'Date Created',
      type: 'date',
      required: false,
      defaultValue: null,
      showOnList: false,
      showOnForm: false
    },
    {
      name: 'afterHours',
      label: 'After Hours',
      type: 'checkbox',
      required: false,
      defaultValue: false,
      showOnList: false,
      showOnForm: true
    },
    {
      name: 'quoted',
      label: 'Quoted',
      type: 'checkbox',
      required: false,
      defaultValue: false,
      showOnList: false,
      showOnForm: true
    },
    {
      name: 'paused',
      label: 'Paused',
      type: 'checkbox',
      required: false,
      defaultValue: false,
      showOnList: false,
      showOnForm: true
    },
    {
      name: 'billable',
      label: 'Billable',
      type: 'checkbox',
      required: false,
      defaultValue: true,
      showOnList: false,
      showOnForm: true
    }
  ],
};
