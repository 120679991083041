// src/components/_Layout/AdminLayout.js

import React, { useRef, useEffect, useState } from 'react';
import { Box, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const AdminLayout = () => {
  const { user } = useAuth();

  const initialPinned = user?.settings?.sidebarPinned ?? true;
  const initialCollapsed = user?.settings?.sidebarCollapsed ?? false;

  const [isPinned, setIsPinned] = useState(initialPinned);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed);

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const { isOpen, onToggle, onClose } = useDisclosure({ defaultIsOpen: true });
  const sidebarRef = useRef(null);

  // If unpinned, close on outside click
  useEffect(() => {
    if (!isPinned) {
      const handleClickOutside = (event) => {
        if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          onClose();
          setSidebarOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isPinned, isOpen, onClose]);

  const handleTogglePin = () => {
    setIsPinned((prev) => !prev);
    if (!isPinned) {
      // if newly pinned, force open
      setSidebarOpen(true);
      onToggle();
    }
  };

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    if (isPinned) {
      setSidebarOpen(!isSidebarOpen);
    } else {
      isOpen ? onClose() : onToggle();
      setSidebarOpen(!isSidebarOpen);
    }
  };

  // Decide the sidebar width
  const sidebarWidth = isSidebarOpen ? (isCollapsed ? '70px' : '240px') : '70px';
  const headerHeight = '56px';

  return (
    <Box bg={bgColor} minH="100vh" position="relative" overflow="hidden">
      {/* SIDEBAR: pinned to the left */}
      <Box
        ref={sidebarRef}
        position="fixed"
        top="0"
        left="0"
        h="100vh"
        w={sidebarWidth}
        transition="width 0.3s"
        zIndex="overlay"
      >
        <Sidebar
          isOpen={isSidebarOpen}
          isCollapsed={isCollapsed}
        />
      </Box>

      {/* HEADER: pinned at top, offset by sidebar width */}
      <Box
        as="header"
        position="fixed"
        top="0"
        left={sidebarWidth}
        right="0"
        h={headerHeight}
        transition="left 0.3s"
        zIndex="docked"
      >
        <Header
          onToggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          isPinned={isPinned}
          isCollapsed={isCollapsed}
          onTogglePin={handleTogglePin}
          onToggleCollapse={handleToggleCollapse}
        />
      </Box>

      {/* MAIN SCROLL AREA: below header, to the right of sidebar */}
      <Box
        as="section"
        position="absolute"
        top={headerHeight}
        left={sidebarWidth}
        right="0"
        bottom="0"
        overflowX="auto"    // <--- allow horizontal scrolling
        overflowY="auto"    // <--- vertical scrolling
        transition="left 0.3s"
        p={6}
      >
        {/* Render your pages here */}
        <Outlet />


      </Box>{
      /* Place footer at the bottom of the scrolled content */}
      <Footer />
    </Box>
  );
};

export default AdminLayout;
