import React, {useState} from 'react';
import {Box, Flex, Heading,} from '@chakra-ui/react';
import TimecardEntries from '../timecard-entries/TimecardEntry'; // A new component to list timecard entries
import WorkOrders from '../projects/ProjectCrud';
import TimecardEntryForm from "../timecard-entries/TimecardEntryForm"; // A new component to list work orders

const TimecardDashboard = () => {
    const [employee, setEmployee] = useState("");
    const [filters, setFilters] = useState({startDate: "", endDate: ""});

    const handleEmployeeChange = (e) => {
        setEmployee(e.target.value);
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters((prev) => ({...prev, [name]: value}));
    };

    return (
        <Flex p={4} w="100vw" h="100vh">

            {/* Right Main Content */}
            <Box flex="1" p={4}>
                {/* Filter Bar */}
                <Box w="100%" p={4} mb={4}>
                    <TimecardEntryForm/>
                </Box>

                {/* Timecard Entries Section */}
                <Box w="100%" p={4} mb={4}>
                    <Heading as="h4" size="md" mb={4}>Time Card Entries</Heading>
                    <TimecardEntries filters={filters}/>
                </Box>

                {/* Related Work Orders Section */}
                <Box w="100%" p={4}>
                    <Heading as="h4" size="md" mb={4}>Work Orders</Heading>
                    <WorkOrders employee={employee}/>
                </Box>
            </Box>
        </Flex>
    );
};

export default TimecardDashboard;
