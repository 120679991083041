// src/pages/admin/alan/Alan.js
import React from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';

const AlanPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {/* Button to open the modal */}
            <Button colorScheme="blue" onClick={onOpen}>
                Open Modal
            </Button>

            {/* Modal Component */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Alan's Modal</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        This is the content inside the modal. You can place any information here.
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="ghost">Another Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AlanPage;
