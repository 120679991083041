// src/config/customer.config.js
export const customerConfig = {
  entity: 'Customer',
  apiEndpoints: {
    list: '/customers',
    create: '/customers',
    update: '/customers/:id',
    delete: '/customers/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "email",
    "label": "Email",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "phone",
    "label": "Phone",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "address1",
    "label": "Address1",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "address2",
    "label": "Address2",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "city",
    "label": "City",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "state",
    "label": "State",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "country",
    "label": "Country",
    "type": "text",
    "required": false,
    "defaultValue": "United States",
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "category",
    "label": "Category",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "salesRep",
    "label": "Salesrep",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "accountID",
    "label": "Accountid",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isIndividual",
    "label": "Isindividual",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "parentCustomer",
    "label": "Parentcustomer",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "parentCustomerName",
    "label": "Parentcustomername",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "externalID",
    "label": "Externalid",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "InternalID",
    "label": "Internalid",
    "type": "number",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
