// src/pages/admin/timecard-entries/TimecardEntryForm.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  RadioGroup,
  Radio,
  Stack,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';


// Example placeholders for fetching dropdown data
const fetchCustomers = async () => [
  { _id: 'c1', name: 'Customer 1' },
  { _id: 'c2', name: 'Customer 2' },
];
const fetchProjects = async (customerId) => [
  { _id: 'p1', name: `Project for ${customerId}` },
  { _id: 'p2', name: `Another Project for ${customerId}` },
];
const fetchServiceItems = async () => [
  { _id: 's1', name: 'Service Item 1' },
  { _id: 's2', name: 'Service Item 2' },
];

function roundToNearest15(minutes) {
  return 15 * Math.round(minutes / 15);
}

function parseTime(timeStr) {
  // Expects "HH:MM AM/PM"
  if (!timeStr.includes(' ')) return [0, 0, 'AM']; // fallback
  const [time, ampm] = timeStr.split(' ');
  const [hour, minute] = time.split(':');
  return [parseInt(hour), parseInt(minute), ampm];
}
function convertTo24h(hour, minute, ampm) {
  let h = hour % 12;
  if (ampm.toUpperCase() === 'PM') h += 12;
  const now = new Date();
  now.setHours(h, minute, 0, 0);
  return now;
}

export default function TimecardEntryForm() {
  const [dateOfEntry, setDateOfEntry] = useState('');
  const [mode, setMode] = useState('WO'); // 'WO' or 'KC'

  const [customerId, setCustomerId] = useState('');
  const [customers, setCustomers] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projects, setProjects] = useState([]);
  const [serviceItemId, setServiceItemId] = useState('');
  const [serviceItems, setServiceItems] = useState([]);

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [description, setDescription] = useState('');

  // Fetch initial dropdown data
  useEffect(() => {
    (async () => setCustomers(await fetchCustomers()))();
    (async () => setServiceItems(await fetchServiceItems()))();
  }, []);

  // When customer changes, fetch Projects
  useEffect(() => {
    if (customerId) {
      (async () => setProjects(await fetchProjects(customerId)))();
    } else {
      setProjects([]);
    }
    setProjectId('');
    setDescription('');
  }, [customerId]);

  // Clear desc if project/service changes
  useEffect(() => {
    setDescription('');
  }, [projectId, serviceItemId]);

  // Recompute totalTime when start/end changes
  useEffect(() => {
    if (startTime && endTime) {
      const [startH, startM, startAP] = parseTime(startTime);
      const [endH, endM, endAP] = parseTime(endTime);

      const start = convertTo24h(startH, startM, startAP);
      const end = convertTo24h(endH, endM, endAP);

      let diff = (end - start) / (1000 * 60);
      if (diff < 0) diff += 24 * 60; // handle crossing midnight

      const rounded = roundToNearest15(diff);
      setTotalTime(`${rounded} min`);
    } else {
      setTotalTime('');
    }
  }, [startTime, endTime]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      dateOfEntry,
      mode,
      customerId,
      projectId,
      serviceItemId,
      startTime,
      endTime,
      totalTime,
      description,
    });
    // Save logic
  };

  // BG color or card style
  const formBg = useColorModeValue('white', 'gray.800');

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      maxW='25%'          // <-- limit form width so it doesn't stretch body
      mx="auto"            // <-- center horizontally
      p={6}
      bg={formBg}
      borderRadius="lg"
      boxShadow="md"
    >
      <FormControl mb={4}>
        <FormLabel>Work Date</FormLabel>
        <Input
          type="date"
          value={dateOfEntry}
          onChange={(e) => setDateOfEntry(e.target.value)}
        />
      </FormControl>

      {/* Radio group for mode */}
      <RadioGroup onChange={setMode} value={mode} mb={4}>
        <Stack direction="row">
          <Radio value="WO">WO</Radio>
          <Radio value="KC">KC</Radio>
        </Stack>
      </RadioGroup>

      {/* Condition: WO mode */}
      {mode === 'WO' && (
        <>
          <FormControl mb={4}>
            <FormLabel>Customer</FormLabel>
            <Select
              placeholder="Select Customer"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
            >
              {customers.map((c) => (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Times in a 2-column layout */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={4}>
            <FormControl>
              <FormLabel>Start Time</FormLabel>
              <Input
                placeholder="HH:MM AM/PM"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Time</FormLabel>
              <Input
                placeholder="HH:MM AM/PM"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          <FormControl mb={4}>
            <FormLabel>Total Time</FormLabel>
            <Input
              isReadOnly
              value={totalTime}
              placeholder="Calculated"
            />
          </FormControl>

          {customerId && (
            <FormControl mb={4}>
              <FormLabel>Project</FormLabel>
              <Select
                placeholder="Select Project"
                value={projectId}
                onChange={(e) => setProjectId(e.target.value)}
              >
                {projects.map((p) => (
                  <option key={p._id} value={p._id}>
                    {p.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {projectId && (
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          )}
        </>
      )}

      {/* Condition: KC mode */}
      {mode === 'KC' && (
        <>
          <FormControl mb={4}>
            <FormLabel>Service Item</FormLabel>
            <Select
              placeholder="Select Service Item"
              value={serviceItemId}
              onChange={(e) => setServiceItemId(e.target.value)}
            >
              {serviceItems.map((s) => (
                <option key={s._id} value={s._id}>
                  {s.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Times in 2-column layout again */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={4}>
            <FormControl>
              <FormLabel>Start Time</FormLabel>
              <Input
                placeholder="HH:MM AM/PM"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Time</FormLabel>
              <Input
                placeholder="HH:MM AM/PM"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          <FormControl mb={4}>
            <FormLabel>Total Time</FormLabel>
            <Input
              isReadOnly
              value={totalTime}
              placeholder="Calculated"
            />
          </FormControl>

          {serviceItemId && (
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          )}
        </>
      )}

      <Button type="submit" colorScheme="blue" w="full" mt={4}>
        Submit
      </Button>
    </Box>
  );
}
