
import React, { useEffect, useState } from 'react';
import DataGrid from '../../../components/_CRUD/DataGrid';
import RightSidePanel from '../../../components/_CRUD/RightSidePanel';
import { userConfig } from '../../../config/user.config';
import { apiClient } from '../../../apiClient';
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";

const UserCrud = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      // If you want server side filtering/sorting:
      // e.g.  const query = new URLSearchParams(params).toString();
      // const response = await apiClient.get(`${userConfig.apiEndpoints.list}?${query}`);
      const response = await apiClient.get(userConfig.apiEndpoints.list);
      setData(response.data);
    } catch (error) {
      toast({
        title: 'Error fetching data.',
        description: error.response?.data?.error || 'Unable to retrieve data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = () => {
    setSelectedItem(null);
    onOpen();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedItem) {
        const endpoint = userConfig.apiEndpoints.update.replace(':id', selectedItem._id);
        await apiClient.put(endpoint, formData);
        toast({
          title: 'User updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await apiClient.post(userConfig.apiEndpoints.create, formData);
        toast({
          title: 'User created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      fetchData();
      onClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <Box>
      <Button colorScheme="blue" onClick={handleCreate}>
        Add User
      </Button>

      <DataGrid
        data={data}
        loading={loading}
        config={userConfig}
        onEdit={handleEdit}
        fetchData={fetchData}
        // if you want server side sorting/filtering:
        // serverSide={true}
      />

      <RightSidePanel
        isOpen={isOpen}
        onClose={onClose}
        config={userConfig}
        item={selectedItem}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default UserCrud;
