// src/context/AuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {apiClient, initializeCsrfToken} from '../apiClient';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                await initializeCsrfToken();
                const response = await apiClient.get('/auth/check');
                setUser(response.data.user);

                if (response.data.user && response.data.user.roles) {
                    const roles = response.data.user.roles.map((role) => role.name);
                    setUserRoles(roles);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setUser(null);
                } else {
                    console.error('Error initializing authentication:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        initializeAuth();
    }, []);

    const login = async (credentials) => {
        try {
            await initializeCsrfToken();
            await apiClient.post('/auth/login', credentials);
            const response = await apiClient.get('/auth/check');
            setUser(response.data.user);

            if (response.data.user && response.data.user.roles) {
                const roles = response.data.user.roles.map((role) => role.name);
                setUserRoles(roles);
            }
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            await initializeCsrfToken();
            await apiClient.post('/auth/logout');
            setUser(null);
            setUserRoles([]);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const updateUserSettings = async (newSettings) => {
        if (!user) return;
        try {
            const merged = { ...user.settings, ...newSettings };
            const response = await apiClient.put('/users/me/settings', merged);
            setUser({ ...user, settings: response.data });
        } catch (e) {
            console.error('Failed to update user settings:', e);
        }
    };
    return (
        <AuthContext.Provider value={{user, userRoles, isAuthenticated: !!user, loading, login, logout, updateUserSettings}}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
