// src/config/user.config.js
export const userConfig = {
  entity: 'User',
  apiEndpoints: {
    list: '/users',
    create: '/users',
    update: '/users/:id',
    delete: '/users/:id',
  },
  fields: [
  {
    "name": "internalId",
    "label": "Internalid",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false
  },
  {
    "name": "externalId",
    "label": "Externalid",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false
  },
  {
    "name": "firstName",
    "label": "Firstname",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "lastName",
    "label": "Lastname",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "email",
    "label": "Email",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "phone",
    "label": "Phone",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "dateHired",
    "label": "Date Hired",
    "type": "date",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": false
  },
  {
    "name": "dateTerminated",
    "label": "Date Terminated",
    "type": "date",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false
  },
  {
    "name": "jobTitle",
    "label": "Job Title",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "department",
    "label": "Department",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/departments",
    "optionsDataPath": "departments",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "team",
    "label": "Team",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false,
    "optionsEndpoint": "/teams",
    "optionsDataPath": "teams",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "payType",
    "label": "Pay Type",
    "type": "select",
    "required": false,
    "defaultValue": "Hourly",
    "showOnList": false,
    "showOnForm": true,
    "options": [
      {
        "label": "Hourly",
        "value": "Hourly"
      },
      {
        "label": "Salary",
        "value": "Salary"
      },
      {
        "label": "N/A",
        "value": "N/A"
      }
    ]
  },
  {
    "name": "location",
    "label": "Location",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": true,
    "optionsEndpoint": "/locations",
    "optionsDataPath": "locations",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "locationInternalId",
    "label": "Locationinternalid",
    "type": "number",
    "required": false,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false
  },
  {
    "name": "roles",
    "label": "Roles",
    "type": "array",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/roles",
    "optionsDataPath": "roles",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "password",
    "label": "Password",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": false,
    "showOnForm": false
  },
  {
    "name": "settings",
    "label": "Settings",
    "type": "object",
    "required": false,
    "showOnList": false,
    "showOnForm": false,
    "fields": [
      {
        "name": "primaryColor",
        "label": "Primarycolor",
        "type": "text",
        "required": false
      },
      {
        "name": "sidebarBg",
        "label": "Sidebarbg",
        "type": "text",
        "required": false
      },
      {
        "name": "colorMode",
        "label": "Colormode",
        "type": "text",
        "required": false
      },
      {
        "name": "_id",
        "label": " Id",
        "type": "text",
        "required": false
      }
    ]
  }
],
};
