// src/components/_Layout/Header.js
import React from 'react';
import {
  Avatar,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiMenu, FiX, FiLock, FiUnlock } from 'react-icons/fi';
import logoUrl from '../../assets/logo.png';
import { useAuth } from '../../context/AuthContext';

const Header = ({
  onToggleSidebar,
  isSidebarOpen,
  isPinned,
  isCollapsed,
  onTogglePin,
  onToggleCollapse,
}) => {
  const navigate = useNavigate();
  const { logout, user, updateUserSettings } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getInitials = (name) => {
    if (!name) return 'User';
    const parts = name.split(' ');
    return parts.map((p) => p[0]).join('').toUpperCase();
  };
  const handleColorModeToggle = () => {
    const newMode = colorMode === 'light' ? 'dark' : 'light';
    toggleColorMode();
    console.log('User: ', user);
    if (user) {
      updateUserSettings({ colorMode: newMode }); // Persist to backend
    }
  };

  const handlePinToggle = () => {
    onTogglePin();
    if (user) {
      updateUserSettings({ sidebarPinned: !isPinned });
    }
  };

  const handleCollapseToggle = () => {
    onToggleCollapse();
    if (user) {
      updateUserSettings({ sidebarCollapsed: !isCollapsed });
    }
  };

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      h="56px"
      px={4}
      bg={bgColor}
      borderBottomWidth="1px"
      borderColor={borderColor}
      shadow="sm"
      position="sticky"
      top={0}
      zIndex={100}
    >
      {/* Left side: Sidebar toggle + Logo */}
      <Flex align="center" gap={3}>
        <IconButton
          aria-label="Toggle Sidebar"
          icon={isSidebarOpen ? <FiX /> : <FiMenu />}
          onClick={onToggleSidebar}
          variant="ghost"
          size="sm"
        />
        <Image src={logoUrl} alt="Company Logo" height="40px" />
      </Flex>

      {/* Right side: toggles + user menu */}
      <HStack spacing={4}>
        {/* UI Settings Menu */}
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} variant="ghost" size="sm">
            UI Settings
          </MenuButton>
          <MenuList>
            {/* Dark/Light Mode Switch */}
            <MenuItem closeOnSelect={false}>
              <Flex align="center" justify="space-between" w="100%">
                <Text>Dark Mode</Text>
                <Switch
                  isChecked={colorMode === 'dark'}
                  onChange={handleColorModeToggle}
                />
              </Flex>
            </MenuItem>

            {/* Pin Sidebar Switch */}
            <MenuItem closeOnSelect={false}>
              <Flex align="center" justify="space-between" w="100%">
                <HStack>
                  {isPinned ? <FiLock /> : <FiUnlock />}
                  <Text>Pin Sidebar</Text>
                </HStack>
                <Switch
                  isChecked={isPinned}
                  onChange={handlePinToggle}
                />
              </Flex>
            </MenuItem>

            {/* Collapse Sidebar Switch */}
            <MenuItem closeOnSelect={false}>
              <Flex align="center" justify="space-between" w="100%">
                <Text>Collapse Sidebar</Text>
                <Switch
                  isChecked={isCollapsed}
                  onChange={handleCollapseToggle}
                />
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>

        {/* User Menu */}
        <Menu>
          <MenuButton as={Button} variant="ghost" size="sm">
            <Avatar size="sm" name={getInitials(user?.name)} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate('/password-reset')}>
              Reset Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

export default Header;
