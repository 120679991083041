// src/components/_Layout/Sidebar.js
import React, {forwardRef} from 'react';
import {
    Box,
    Divider,
    Icon,
    Link,
    Spacer,
    Text,
    Tooltip,
    useBreakpointValue,
    useColorMode,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import {NavLink, useLocation} from 'react-router-dom';
import {FaBaby, FaBoxes, FaClock, FaCog, FaDashcube, FaWrench,} from 'react-icons/fa';
import {FiUser} from 'react-icons/fi';
import {PiBarnFill} from "react-icons/pi";
import {MdOilBarrel} from "react-icons/md";

const Sidebar = forwardRef(({isOpen, isCollapsed}, ref) => {
    const isDesktop = useBreakpointValue({base: false, md: true});
    const {colorMode} = useColorMode();
    const bg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const hoverBg = useColorModeValue('gray.100', 'gray.700');
    const activeBg = colorMode === 'light' ? 'blue.50' : 'blue.900';
    const activeColor = 'blue.500';
    const textColor = useColorModeValue('gray.600', 'gray.300');


    const navItems = [
        {label: 'Dashboard', icon: FaDashcube, to: '/admin/dashboard'},
        {label: 'Customers', icon: PiBarnFill, to: '/admin/customers'},
        {label: 'Work Orders', icon: FaWrench, to: '/admin/projects'},
        {label: 'Inventory', icon: FaBoxes, to: '/admin/inventory'},
        {label: 'Hygiene', icon: MdOilBarrel, to: '/admin/hygiene'},
        {label: 'Time Card Dashboard', icon: FaClock, to: '/admin/timecard-dashboard'},
        {label: 'Time Cards', icon: FaClock, to: '/admin/timecards'},
        {label: 'Time Card Entries', icon: FaClock, to: '/admin/timecard-entries'},
        {label: 'Settings', icon: FaCog, to: '/admin/settings'},
        {label: 'Alan', icon: FaBaby, to: '/admin/alan'},
    ];


    const resourceItems = [
        {label: 'Directory', icon: FiUser, to: '/admin/directory'},
        {label: 'Business Manager', icon: FiUser, to: '/admin/business-manager'},
        {label: 'HR Manager', icon: FiUser, to: '/admin/hr-manager'},
        {label: 'Payroll Manager', icon: FiUser, to: '/admin/payroll-manager'},
        {label: 'IT Manager', icon: FiUser, to: '/admin/it-manager'},
    ];

    const location = useLocation();

    // If not open on mobile, hide entirely
    // But on desktop, always keep 70px for icons if closed
    const sidebarWidth = isOpen
        ? isCollapsed
            ? '70px'
            : '240px'
        : '70px';

    if (!isOpen && !isDesktop) {
        return null;
    }

    // Single function to build each NavLink
    const renderLink = (item) => {
        const isActive = location.pathname === item.to;
        const linkHighlight = isActive ? activeBg : 'transparent';
        const linkColor = isActive ? activeColor : textColor;

        // The link content (icon + optional label)
        const linkInner = (
            <>
                {/* The left highlight bar if active */}
                {isActive && (
                    <Box
                        position="absolute"
                        left="0"
                        top="50%"
                        transform="translateY(-50%)"
                        w="4px"
                        h="60%"
                        bg="blue.500"
                        borderRightRadius="md"
                    />
                )}

                {/* The icon */}
                <Icon
                    as={item.icon}
                    boxSize={6}
                    flexShrink={0}
                    // zero out margins if collapsed
                    ml={isCollapsed ? 0 : 3}
                    mr={isCollapsed ? 0 : 3}
                />

                {/* Only render text if not collapsed */}
                {!isCollapsed && (
                    <Text
                        whiteSpace="nowrap"
                        fontWeight={isActive ? 'semibold' : 'medium'}
                    >
                        {item.label}
                    </Text>
                )}
            </>
        );

        return (
            <Link
                as={NavLink}
                to={item.to}
                exact="true"
                display="flex"
                alignItems="center"
                position="relative"
                p={2}
                borderRadius="md"
                color={linkColor}
                bg={linkHighlight}
                _hover={{
                    bg: !isActive && hoverBg,
                    color: !isActive && (colorMode === 'light' ? 'gray.800' : 'white'),
                }}
                transition="all 0.2s"
                overflow="hidden"
            >
                {linkInner}
            </Link>
        );
    };

    // If collapsed, wrap the link in a Tooltip
    const renderLinkWithOptionalTooltip = (item) => {
        if (isCollapsed) {
            return (
                <Tooltip label={item.label} placement="right" hasArrow>
                    <Box>{renderLink(item)}</Box>
                </Tooltip>
            );
        }
        return <Box>{renderLink(item)}</Box>;
    };

    return (
        <Box
            ref={ref}
            as="nav"
            w={sidebarWidth}
            bg={bg}
            minH="100vh"
            p={2}
            position="fixed"
            top="0"
            left="0"
            borderRightWidth="1px"
            borderColor={borderColor}
            transition="width 0.3s ease-in-out"
            // Make sure the tooltip has space to show on the right
            // If you still get clipping, try "overflow='visible'" instead
            overflowX="visible"
            overflowY="auto"
            zIndex="overlay"
            pt="56px"
        >
            <VStack align="stretch" spacing={1} flex="1">
                {navItems.map((item) => (
                    <Box key={item.label}>
                        {renderLinkWithOptionalTooltip(item)}
                    </Box>
                ))}
            </VStack>

            <Spacer/>
            <Spacer/>
            <Divider/>

            <VStack align="stretch" spacing={1} mt={2} mb={2}>
                {resourceItems.map((item) => (
                    <Box key={item.label}>
                        {renderLinkWithOptionalTooltip(item)}
                    </Box>
                ))}
            </VStack>
        </Box>
    );
});

export default Sidebar;
