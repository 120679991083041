// /src/pages/admin/projects/ProjectCrud.js

import React, { useEffect, useState } from 'react';
import { projectConfig } from '../../../config/project.config';
import { apiClient } from '../../../apiClient';
import DataGrid from '../../../components/_CRUD/DataGrid';
import RightSidePanel from '../../../components/_CRUD/RightSidePanel';
import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';

const ProjectCrud = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // We'll store the current page
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Adjust as you like

  // Example advancedFilterFields
  const advancedFilterFields = [
    { name: 'woId', label: 'WO ID Filter', type: 'text' },
    { name: 'customer', label: 'Customer Filter', type: 'select',
      optionsEndpoint: '/customers', optionsDataPath: 'customers',
      optionLabel: 'name', optionValue: '_id'
    },
  ];

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const page = params.page || currentPage;
      const { filter, woId, customer } = params;

      const queryParams = { page, limit: pageSize };
      if (woId) queryParams.woId = woId;
      if (customer) queryParams.customer = customer;
      // add filter

      const response = await apiClient.get(projectConfig.apiEndpoints.list, {
        params: queryParams,
      });

      // Expect { data, total }
      console.log('Backend returned:', response.data);
      setData(response.data.data);
      setTotal(response.data.total);

      if (params.page) setCurrentPage(params.page);

    } catch (error) {
      toast({
        title: 'Error fetching data.',
        description: error.response?.data?.error || 'Unable to retrieve data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ page: 1 });
    // eslint-disable-next-line
  }, []);

  const handleCreate = () => {
    setSelectedItem(null);
    onOpen();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedItem) {
        const endpoint = projectConfig.apiEndpoints.update.replace(':id', selectedItem._id);
        await apiClient.put(endpoint, formData);
        toast({
          title: 'Project updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await apiClient.post(projectConfig.apiEndpoints.create, formData);
        toast({
          title: 'Project created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      fetchData(); // Refresh
      onClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <Box>
      <Button colorScheme="blue" onClick={handleCreate} mb={4}>
        Add Work Order
      </Button>

      <DataGrid
        data={data}
        loading={loading}
        config={projectConfig}
        onEdit={handleEdit}
        fetchData={fetchData}
        serverSide={true}
        pagination={true}
        pageSize={pageSize}
        advancedFilterFields={advancedFilterFields}
      />

      <RightSidePanel
        isOpen={isOpen}
        onClose={onClose}
        config={projectConfig}
        item={selectedItem}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default ProjectCrud;
