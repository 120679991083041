// src/pages/admin/Settings.js
import React from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

// Import CRUD components
import LocationCrud from "./locations/LocationCrud";
import RoleCrud from "./roles/RoleCrud";
import PermissionCrud from "./permissions/PermissionCrud";
import ServiceItemCrud from "./service-items/ServiceItemCrud";
import TeamCrud from "./teams/TeamCrud";
import TimecardEntryOptionCrud from "./timecard-entry-options/TimecardEntryOptionCrud";
import UserCrud from "./users/UserCrud";
import DepartmentCrud from "./departments/DepartmentCrud";

const Settings = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box>
      <Heading mb={6}>Settings</Heading>
      <Tabs variant="enclosed" colorScheme="red">
        <TabList>
          <Tab>UI</Tab>
          <Tab>Users</Tab>
          <Tab>Locations</Tab>
          <Tab>Departments</Tab>
          <Tab>Roles</Tab>
          <Tab>Permissions</Tab>
          <Tab>Service Items</Tab>
          <Tab>Teams</Tab>
          <Tab>Timecard Entry Options</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Dark Mode</FormLabel>
              <Switch isChecked={colorMode === "dark"} onChange={toggleColorMode} />
            </FormControl>
          </TabPanel>

          <TabPanel>
            <UserCrud />
          </TabPanel>

            <TabPanel>
                <LocationCrud />
            </TabPanel>
            <TabPanel>
                <DepartmentCrud />
            </TabPanel>
            <TabPanel>
                <RoleCrud />
            </TabPanel>
            <TabPanel>
                <PermissionCrud />
            </TabPanel>
            <TabPanel>
                <ServiceItemCrud />
            </TabPanel>
            <TabPanel>
                <TeamCrud />
            </TabPanel>
            <TabPanel>
                <TimecardEntryOptionCrud />
            </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Settings;