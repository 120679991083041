// File: /src/components/_CRUD/DataGrid.js

import React, { useEffect, useState, useMemo } from 'react';
import {
  Badge,
  Box,
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Wrap,
  WrapItem,
  useColorModeValue,
  Input,
  IconButton,
  Flex,
  Text,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { apiClient } from '../../apiClient';
import PermissionsCell from '../_UI/PermissionsCell';

const DataGrid = ({
  data,
  loading,
  config,
  onEdit,
  fetchData,
  serverSide = false,
  pagination = false,
  pageSize = 10,
  advancedFilterFields = [],
}) => {
  const [filterText, setFilterText] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [advancedFilters, setAdvancedFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [optionsData, setOptionsData] = useState({});
  const toast = useToast();
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  // 1) load select options
  useEffect(() => {
    const fetchOptions = async () => {
      const newOptionsData = {};
      for (const field of config.fields) {
        if (['select', 'array', 'object'].includes(field.type) && field.optionsEndpoint) {
          try {
            const resp = await apiClient.get(field.optionsEndpoint);
            newOptionsData[field.name] = resp.data[field.optionsDataPath];
          } catch (err) {
            console.error(`Error fetching options for ${field.name}:`, err);
          }
        }
      }
      setOptionsData(newOptionsData);
    };
    fetchOptions();
  }, [config.fields]);

  const listFields = config.fields.filter((f) => f.showOnList !== false);

  // 2) advanced filter
  const handleAdvancedFilterChange = (filterName, value) => {
    setAdvancedFilters((prev) => ({ ...prev, [filterName]: value }));
    if (serverSide && fetchData) {
      fetchData({
        ...advancedFilters, // previous filters
        [filterName]: value, // override this one
        filter: filterText,
        sort: sortField,
        order: sortOrder,
        page: currentPage,
      });
    }
  };

  // 3) client side filtering/sorting
  const filteredData = useMemo(() => {
    if (serverSide) {
      return data;
    }
    let result = [...data];
    if (filterText) {
      const lower = filterText.toLowerCase();
      result = result.filter((item) =>
        listFields.some((field) => {
          const val = item[field.name];
          if (!val) return false;
          return String(val).toLowerCase().includes(lower);
        })
      );
    }
    advancedFilterFields.forEach((ff) => {
      const val = advancedFilters[ff.name];
      if (val) {
        if (ff.type === 'select') {
          result = result.filter((i) => i[ff.name] === val);
        } else if (ff.type === 'text') {
          const lowerVal = val.toLowerCase();
          result = result.filter((i) => {
            const itemVal = i[ff.name] || '';
            return String(itemVal).toLowerCase().includes(lowerVal);
          });
        }
      }
    });
    return result;
  }, [serverSide, data, filterText, advancedFilterFields, advancedFilters, listFields]);

  const sortedData = useMemo(() => {
    if (!sortField || serverSide) return filteredData;
    const fieldConfig = listFields.find((f) => f.name === sortField);
    if (!fieldConfig) return filteredData;
    const sorted = [...filteredData].sort((a, b) => {
      const valA = a[sortField];
      const valB = b[sortField];
      if (valA === valB) return 0;
      if (valA == null) return 1;
      if (valB == null) return -1;

      if (fieldConfig.type === 'number') return valA - valB;
      return String(valA).localeCompare(String(valB));
    });
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }, [filteredData, sortField, sortOrder, serverSide, listFields]);

  // pagination
  let finalData = sortedData;
  const totalItems = finalData.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  if (pagination && !serverSide) {
    const startIndex = (currentPage - 1) * pageSize;
    finalData = finalData.slice(startIndex, startIndex + pageSize);
  }

  console.log('finalData in DataGrid:', finalData); // <--- debug log

  // handle delete
  const handleDelete = async (id) => {
    if (!window.confirm(`Are you sure you want to delete this ${config.entity}?`)) {
      return;
    }
    try {
      await apiClient.delete(config.apiEndpoints.delete.replace(':id', id));
      if (fetchData) fetchData();
    } catch (err) {
      console.error('Error deleting:', err);
      toast({
        title: 'Error deleting item.',
        description: err.message || 'Unable to delete the item.',
        status: 'error',
      });
    }
  };

  // handle sort
  const handleSort = (fieldName) => {
    if (serverSide && fetchData) {
      const newOrder = sortField === fieldName && sortOrder === 'asc' ? 'desc' : 'asc';
      setSortField(fieldName);
      setSortOrder(newOrder);
      fetchData({
        sort: fieldName,
        order: newOrder,
        filter: filterText,
        page: currentPage,
        ...advancedFilters,
      });
      return;
    }
    if (sortField === fieldName) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(fieldName);
      setSortOrder('asc');
    }
  };

  // pagination nav
  const nextPage = () => {
    const newPage = currentPage + 1;
    if (serverSide && fetchData) {
      fetchData({
        sort: sortField,
        order: sortOrder,
        filter: filterText,
        page: newPage,
        ...advancedFilters,
      });
    }
    setCurrentPage(newPage);
  };
  const prevPage = () => {
    const newPage = currentPage - 1;
    if (serverSide && fetchData) {
      fetchData({
        sort: sortField,
        order: sortOrder,
        filter: filterText,
        page: newPage,
        ...advancedFilters,
      });
    }
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box>
      {/* Global text filter */}
      <Flex mb={4} gap={2} align="center" flexWrap="wrap">
        <Text>Filter:</Text>
        <Input
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
            if (serverSide && fetchData) {
              fetchData({
                filter: e.target.value,
                sort: sortField,
                order: sortOrder,
                page: currentPage,
                ...advancedFilters,
              });
            }
          }}
          placeholder={`Search ${config.entity}...`}
          maxW="300px"
        />

        {/* advanced filters */}
        {advancedFilterFields.map((filterField) => {
          const fValue = advancedFilters[filterField.name] || '';
          if (filterField.type === 'select') {
            const fieldOptions = optionsData[filterField.name] || [];
            return (
              <Box key={filterField.name} ml={2}>
                <Text fontSize="sm">{filterField.label}</Text>
                <ChakraSelect
                  size="sm"
                  placeholder={`Select ${filterField.label}`}
                  value={fValue}
                  onChange={(e) => handleAdvancedFilterChange(filterField.name, e.target.value)}
                >
                  {fieldOptions.map((opt) => (
                    <option key={opt._id || opt.value} value={opt._id || opt.value}>
                      {opt.name || opt.label}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
            );
          }
          if (filterField.type === 'text') {
            return (
              <Box key={filterField.name} ml={2}>
                <Text fontSize="sm">{filterField.label}</Text>
                <Input
                  size="sm"
                  placeholder={filterField.label}
                  value={fValue}
                  onChange={(e) => handleAdvancedFilterChange(filterField.name, e.target.value)}
                />
              </Box>
            );
          }
          if (filterField.type === 'date') {
            return (
              <Box key={filterField.name} ml={2}>
                <Text fontSize="sm">{filterField.label}</Text>
                <Input
                  size="sm"
                  type="date"
                  value={fValue}
                  onChange={(e) => handleAdvancedFilterChange(filterField.name, e.target.value)}
                />
              </Box>
            );
          }
          return null;
        })}
      </Flex>

      <Box overflowX="auto">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              {listFields.map((field) => {
                let icon = <FaSort />;
                if (sortField === field.name && sortOrder === 'asc') icon = <FaSortUp />;
                else if (sortField === field.name && sortOrder === 'desc') icon = <FaSortDown />;

                return (
                  <Th
                    key={field.name}
                    cursor="pointer"
                    whiteSpace="nowrap"
                    onClick={() => handleSort(field.name)}
                  >
                    <Flex align="center" gap={1}>
                      {field.label}
                      <IconButton
                        aria-label="sort"
                        icon={icon}
                        variant="unstyled"
                        size="xs"
                        pointerEvents="none"
                      />
                    </Flex>
                  </Th>
                );
              })}
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {finalData.map((item) => (
              <Tr key={item._id} _hover={{ bg: hoverBg }}>
                {listFields.map((field) => (
                  <Td key={field.name}>{renderCellContent(item, field, optionsData)}</Td>
                ))}
                <Td whiteSpace="nowrap">
                  <Button
                    size="xs"
                    mr={2}
                    colorScheme="blue"
                    leftIcon={<EditIcon />}
                    onClick={() => onEdit(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="xs"
                    colorScheme="red"
                    leftIcon={<DeleteIcon />}
                    onClick={() => handleDelete(item._id)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {pagination && (
        <Flex mt={4} align="center" justify="space-between">
          <Text>
            Page <b>{currentPage}</b>
            {!serverSide && <> of <b>{totalPages}</b></>}
          </Text>
          <Flex gap={2}>
            <Button size="sm" onClick={prevPage} isDisabled={currentPage <= 1}>
              Prev
            </Button>
            <Button size="sm" onClick={nextPage}>
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

function renderCellContent(item, field, optionsData) {
  const value = item[field.name];
  // e.g. field: { name: 'woId', label: 'WO ID', type: 'text' }

  switch (field.type) {
    case 'select':
      if (value && typeof value === 'object') {
        // If it's a populated object, show .name or custom .optionLabel
        return value[field.optionLabel] || value.name || 'N/A';
      } else {
        // If it's just an ID, see if we can look it up in the cached options
        const opts = optionsData[field.name] || [];
        const found = opts.find((o) => o[field.optionValue] === value);
        return found ? found[field.optionLabel] : value || 'N/A';
      }

    case 'checkbox':
      return value ? 'Yes' : 'No';

    case 'date':
      if (!value) return 'N/A';
      return new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }).format(new Date(value));

    default:
      // For 'text' or 'number', or anything else
      if (value && typeof value === 'object') {
        if (value.name) return value.name;
        return JSON.stringify(value);
      }
      return value || 'N/A';
  }
}

export default DataGrid;
